import React, { useState, useEffect, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import '../css/Nav.css';
import Logo from '../images/logoweb.png';
import axios from 'axios';
import { BASE_URL } from './config/keys';
import { useNavigate } from 'react-router-dom';

const Nav = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { t, i18n } = useTranslation();
    const [language, setLanguage] = useState(i18n.language);
    const [isOpen, setIsOpen] = useState(false);
    const [inputText, setInputText] = useState('');
    const [products, setProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    // const fetchData = async () => {
    //     try {
    //         console.log('BASE_URL:', BASE_URL);
    //         const response = await axios.get(${BASE_URL}products?lang=${i18n.language});
    //                     setProducts(response.data.data);
    //     } catch (error) {
    //         console.error('Error fetching products:', error);
    //     }
    // };

   // Create axios instance with timeout
   const axiosInstance = axios.create({
    timeout: 10000, // 10 second timeout
    retries: 3,
    retryDelay: 1000
});

// Retry logic for failed requests
const fetchWithRetry = async (url, retries = 3, delay = 1000) => {
    try {
        const response = await axiosInstance.get(url);
        return response;
    } catch (error) {
        if (retries > 0) {
            console.log(`Retrying request. Attempts left: ${retries - 1}`);
            await new Promise(resolve => setTimeout(resolve, delay));
            return fetchWithRetry(url, retries - 1, delay);
        }
        throw error;
    }
};

const fetchData = useCallback(async () => {
    try {
        setIsLoading(true);
        setError(null);
        
        const url = `${BASE_URL}products?lang=${i18n.language}`;
        console.log('Fetching products from:', url);
        console.log('Current language:', i18n.language);
        
        const response = await fetchWithRetry(url);
        console.log("Raw response:", response);
        console.log("Products received:", response.data?.data?.length || 0);
        
        if (response.data && response.data.data) {
            setProducts(response.data.data);
            console.log("Sample product:", response.data.data[0]); // Log first product structure
        } else {
            console.warn("Unexpected response format:", response.data);
            setProducts([]);
        }
    } catch (error) {
        console.error('Error fetching products:', error);
        console.error('Error details:', {
            message: error.message,
            response: error.response,
            status: error.response?.status
        });
        setError(error);
        setProducts([]);
    } finally {
        setIsLoading(false);
    }
}, [i18n.language]);

// Initial fetch
useEffect(() => {
    fetchData();
}, [fetchData]);

// Periodic refresh of data
useEffect(() => {
    const refreshInterval = setInterval(() => {
        fetchData();
    }, 300000); // Refresh every 5 minutes

    return () => clearInterval(refreshInterval);
}, [fetchData]);

    
    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const handleLinkClick = () => {
        setIsOpen(false);
    };

    const handleInputChange = (event) => {
        const value = event.target.value;
        console.log('Search input changed:', value); // Add logging
        setInputText(value);
    };

    const handleProductClick = (product) => {
        console.log('Product clicked:', product); // Add logging
        navigate(`/product_detail/${product.id}`);
    };

    const changeLanguage = (lang) => {
        localStorage.setItem('language', lang);
        setLanguage(lang);
        i18n.changeLanguage(lang);
    };

    const fetchAllProducts = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/products/all`);
            setProducts(response.data.data);
            setIsOpen(false); 
        } catch (error) {
            console.error('Error fetching all products:', error);
        }
    };
    const [showSuggestions, setShowSuggestions] = useState(false);

    const fetchProductsByCategory = async (category) => {
        try {
            if (!category) {
                fetchAllProducts();
                return;
            }
            const response = await axios.get(`${BASE_URL}/products/category/${category}`);
            setProducts(response.data.data);
            setIsOpen(false); 
        } catch (error) {
            console.error(`Error fetching products for category ${category}:`, error);
        }
    };

    return (
        <>
            <nav className="navbar navbar-expand-lg w-100">
                <div className="container row m-0 p-0" style={{ maxWidth: '100%' }}>
                    <div className="col-4 pt-0 div_logo">
                        <div className="d-flex align-items-center flex-wrap" style={{ marginBottom: '-56px' }}>
                            <Link className="navbar-brand" to="/" style={{ textDecoration: 'none' }}>
                                <div className="d-flex align-items-center">
                                    <img src={Logo} alt="...logo" className="img-responsive" style={{ zoom: '22%', marginTop: '-9%', marginLeft: '15%' }} />
                                    <div className="div_title_logo">
                                        <div className="fs-4" style={{ color: '#000059' }}>PRAT DUMAS</div>
                                        <div style={{ color: '#0074A3' }}>
    {i18n.language === 'fr' ? 'Depuis 1309' : 'Since 1309'}
</div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className="col-8 p-0">
                        <button
                            className="navbar-toggler float-end mr-5 mt-1"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent"
                            aria-controls="navbarSupportedContent"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                            style={{ marginInlineEnd: '15px' }}
                            onClick={handleToggle}
                        >
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className={`${isOpen ? '' : 'collapse navbar-collapse'}`} id="navbarSupportedContent">
                            <ul className="navbar-nav mb-2 mb-lg-0" style={{ fontFamily: 'Open Sans', fontSize: '12.5pt' }}>
                                <NavItem to="/" text={t('home')} active={location.pathname === '/'} onClick={handleLinkClick} />
                                <NavItem to="/products" text={t('products')} active={location.pathname === '/products' || location.pathname.startsWith('/product_detail')} onClick={() => fetchProductsByCategory()} />
                                <NavItem to="/part_dumas" text={t('prat_dumas')} active={location.pathname === '/part_dumas' || location.pathname.startsWith('/prat_dumas_detail')} onClick={handleLinkClick} />
                                <NavItem to="/contact" text={t('contact')} active={location.pathname === '/contact'} onClick={handleLinkClick} />
                            </ul>
                            <div className='langue ms-auto me-5'>
                                <LanguageSwitcher currentLanguage={i18n.language} onChangeLanguage={changeLanguage} />
                            </div>
                        </div>
                        <div className={`${isOpen ? ' dropdown-menu' : 'd-none'}`} id="navbarSupportedContent">
                            <ul className="navbar-nav mb-2 mb-lg-0" style={{ fontFamily: 'Open Sans', fontSize: '12.5pt' }}>
                                <NavItem to="/" text={t('home')} active={location.pathname === '/'} onClick={handleLinkClick} />
                                <NavItem to="/products" text={t('products')} active={location.pathname === '/products' || location.pathname.startsWith('/product_detail')} onClick={() => fetchProductsByCategory()} />
                                <NavItem to="/part_dumas" text={t('prat_dumas')} active={location.pathname === '/part_dumas' || location.pathname.startsWith('/prat_dumas_detail')} onClick={handleLinkClick} />
                                <NavItem to="/contact" text={t('contact')} active={location.pathname === '/contact'} onClick={handleLinkClick} />
                            </ul>
                            <div className='langue ms-auto me-5'>
                                <LanguageSwitcher currentLanguage={i18n.language} onChangeLanguage={changeLanguage} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid d-flex justify-content-end p-2 div_search">
                    <div className="col-md-4 col-sm-6">
                        <SearchForm 
                            inputText={inputText} 
                            onInputChange={handleInputChange} 
                            onProductClick={handleProductClick} 
                            placeholder={t('search_placeholder')} 
                            products={products} 
                            showSuggestions={showSuggestions} 
                            setShowSuggestions={setShowSuggestions}
                            setInputText={setInputText}
                            i18n={i18n}
                            isLoading={isLoading}
                            error={error}
                        />
                    </div>
                </div>
            </nav>
        </>
    );
};


const NavItem = ({ to, text, active, onClick }) => (
    <li className="nav-item">
        <Link className={`nav-link ${active ? 'active' : ''}`} to={to} style={{ color: active ? 'white' : '#164181', textDecoration: active ? 'underline' : 'none' }} onClick={onClick}>
            {text}
        </Link>
    </li>
);

const LanguageSwitcher = ({ currentLanguage, onChangeLanguage }) => (
    <div className='d-flex'>
        <Link className={`nav-link ${currentLanguage === 'en' ? 'text-white' : ''}`} to="#" onClick={() => onChangeLanguage('en')}>EN</Link> / <Link className={`nav-link ${currentLanguage === 'fr' ? 'text-white' : ''}`} to="#" style={{ color: '#164181' }} onClick={() => onChangeLanguage('fr')}>FR</Link>
    </div>
);

// const SearchForm = ({ inputText, onInputChange, onProductClick, placeholder }) => (
//     <div className="form-group has-search d-flex">
//         <input type="text" className="form-control custom-input" value={inputText} onChange={onInputChange} placeholder={placeholder} />
//         <span role='button' className="input-group-text ml-auto" id="basic-addon1" style={{ backgroundColor: '#38A3BC', color: 'white', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }} onClick={onProductClick}>
//             <svg className="bi bi-search" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
//                 <path fillRule="evenodd" d="M10.832 9.48a5.5 5.5 0 1 0-1.352 1.352l3.704 3.704a1 1 0 0 0 1.415-1.415l-3.704-3.704zM2 6.5a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0z" />
//             </svg>
//         </span>
//     </div>
// );

const SearchForm = ({ 
    inputText, 
    onInputChange, 
    onProductClick, 
    placeholder, 
    products, 
    showSuggestions, 
    setShowSuggestions,
    setInputText,
    i18n,
    isLoading,
    error
}) => {
    // Add debugging useEffect
    useEffect(() => {
        console.log('SearchForm state:', {
            inputText,
            productsCount: products?.length,
            showSuggestions,
            isLoading,
            error: error?.message,
            currentLanguage: i18n.language,
            sampleProduct: products?.[0]
        });
    }, [inputText, products, showSuggestions, isLoading, error, i18n.language]);

    // Updated filtering logic to handle both title structures
    const filteredProducts = products?.filter(product => {
        // Handle both new and old title structure
        const title = (i18n.language === 'fr' ? 
            (product.title_fr || product.title) : 
            (product.title_en || product.title)
        )?.toLowerCase();
        
        const searchTerm = inputText.toLowerCase();
        
        console.log('Filtering product:', {
            productId: product.id,
            title,
            searchTerm,
            matches: title?.includes(searchTerm)
        });

        return title && searchTerm && title.includes(searchTerm);
    }) || [];

    console.log('Filtered products:', {
        searchTerm: inputText,
        totalProducts: products?.length,
        filteredCount: filteredProducts.length,
        sampleFilteredProduct: filteredProducts[0],
        searchLanguage: i18n.language
    });

    // Update the no results message to be language-aware
    const noResultsMessage = i18n.language === 'fr' ? 
        'Aucun produit trouvé' : 
        'No products found';

    return (
        <div className="position-relative">
            <div className="form-group has-search d-flex">
                <input 
                    type="text" 
                    className="form-control custom-input"
                    value={inputText}
                    onChange={onInputChange}
                    onFocus={() => {
                        console.log('Search input focused');
                        setShowSuggestions(true);
                    }}
                    placeholder={placeholder} 
                    disabled={isLoading}
                />
                <span 
                    role='button' 
                    className="input-group-text ml-auto" 
                    id="basic-addon1" 
                    style={{ backgroundColor: '#38A3BC', color: 'white', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                    onClick={() => {
                        console.log("Search button clicked", {
                            hasProducts: products?.length > 0,
                            filteredCount: filteredProducts.length,
                            searchTerm: inputText
                        });
                        if (filteredProducts.length > 0) {
                            onProductClick(filteredProducts[0]);
                        }
                    }}
                >
                    <svg className="bi bi-search" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M10.832 9.48a5.5 5.5 0 1 0-1.352 1.352l3.704 3.704a1 1 0 0 0 1.415-1.415l-3.704-3.704zM2 6.5a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0z" />
                    </svg>
                </span>
            </div>

            {showSuggestions && inputText && (
                <ul className="list-group position-absolute w-100 bg-white shadow" style={{ zIndex: 1000, maxHeight: '250px', overflowY: 'auto' }}>
                    {filteredProducts.length > 0 ? (
                        filteredProducts.map(product => {
                            const title = i18n.language === 'fr' ? 
                                (product.title_fr || product.title) : 
                                (product.title_en || product.title);
                            return (
                                <li 
                                    key={product.id} 
                                    className="list-group-item list-group-item-action" 
                                    onClick={() => {
                                        onProductClick(product);
                                        setShowSuggestions(false);
                                        setInputText('');
                                    }}
                                    style={{ cursor: 'pointer' }}
                                >
                                    {title}
                                </li>
                            );
                        })
                    ) : (
                        <li className="list-group-item">{noResultsMessage}</li>
                    )}
                </ul>
            )}

            {isLoading && (
                <div className="position-absolute w-100 text-center p-2 bg-white">
                    {i18n.language === 'fr' ? 'Chargement des produits...' : 'Loading products...'}
                </div>
            )}

            {error && (
                <div className="position-absolute w-100 text-center p-2 text-danger bg-white">
                    {i18n.language === 'fr' ? 'Erreur: ' : 'Error: '}{error.message}
                </div>
            )}
        </div>
    );
};


export default Nav;